class Video {
  constructor() {
    this.playButton = document.querySelectorAll('.lc-video__thumbnail-container');

    if(this.playButton){
      this.checkThumbnail();
      this.bindPlay();
    }
  }

  checkThumbnail() {
    this.playButton.forEach(button => {
      console.log(button.querySelector('.lc-video__thumbnail').style.backgroundImage);

      if(!button.querySelector('.lc-video__thumbnail').style.backgroundImage){
        button.style.display = 'none';
      }
    });
  }

  bindPlay() {
    this.playButton.forEach(button => {
      button.addEventListener('click', () => {

        const video = button.parentElement.parentElement;
        const thumbnail = button.nextSibling;
        const iframe = video.querySelector('iframe');
        const videoContainer = video.querySelector('.lc-video__video');
        const link = button.parentElement.nextSibling;

        iframe.src = iframe.src + '&autoplay=1';
        button.style.display = 'none';
        thumbnail.style.display = 'none';
        videoContainer.style.display = 'block';

        if (link) {
          link.style.display = 'none';
        }
      });
    });
  }

} export default Video;
