import Swiper from '../lib/swiper';

class Slider {
	constructor() {
    this.slider = document.querySelector('.lc-slider__wrapper');
    this.sliderItems = Array.prototype.slice.call(document.querySelectorAll('.lc-slider-item'));

    this.itemsPerContainer = 22;
    this.numChildrenPerDiv = [6, 5, 6, 5];

    window.addEventListener('resize', () => {
      if(this.slider && this.sliderItems){
        this.resetSlider();
      }
    });

    if(this.slider && this.sliderItems){
      this.getSettings();
      this.wrapItems();
    }
  }

  resetSlider() {
    this.sliderItems.forEach(item => {
      this.slider.appendChild(item);
    });

    Array.prototype.slice.call(this.slider.children).forEach(child => {
      if(!child.classList.contains('lc-slider-item')){
        child.remove();
      }
    });

    this.getSettings();
    this.wrapItems();
  }

  getSettings() {
    if(window.innerWidth <= 768){
      this.itemsPerContainer = 6;
      this.numChildrenPerDiv = [2, 1, 2, 1];
    } else if(window.innerWidth <= 1024){
      this.itemsPerContainer = 14;
      this.numChildrenPerDiv = [4, 3, 4, 3];
    } else {
      this.itemsPerContainer = 22;
      this.numChildrenPerDiv = [6, 5, 6, 5];
    }
  }

  async wrapItems(){
    let container;

    for (let i = 0; i < this.sliderItems.length; i += this.itemsPerContainer) {

      container = document.createElement('div');
      container.className = 'lc-slider-container';

      for (let j = i; j < i + this.itemsPerContainer && j < this.sliderItems.length; j++) {
        container.appendChild(this.sliderItems[j]);
      }

      this.slider.appendChild(container);
    }

    this.sliderContainer = document.querySelectorAll('.lc-slider-container');
    this.sliderContainer = Array.prototype.slice.call(this.sliderContainer);

    this.sliderContainer.forEach(container => {

      let sliderItems = container.children;

      for (let index = 0; index <= 3; index++) {
        const row = document.createElement("div");
        row.className = 'lc-slider-row';

        const numChildren = this.numChildrenPerDiv[index];
        for (let i = 0; i < numChildren; i++) {
            const childContent = sliderItems[0];
            if (childContent && !childContent.classList.contains('lc-slider-row')) {
                row.appendChild(childContent);
            }
        }

        container.appendChild(row);
      }
    });

    const sliderContainer = Array.from(this.slider.querySelectorAll('.lc-slider-container'));

    for (let i = sliderContainer.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [sliderContainer[i], sliderContainer[j]] = [sliderContainer[j], sliderContainer[i]];
    }

    sliderContainer.forEach(container => this.slider.appendChild(container));

    const result = await this.initSlider();
  }

  initSlider() {
    return new Promise((resolve) => {
      this.sliderInstance = new Swiper('.lc-slider', {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        loop: true,
        speed: 500,
        slideClass: 'lc-slider-container',
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      });

      let isPlaying = true;

      this.slider.addEventListener('click', () => {
        if(isPlaying) {
          this.sliderInstance.autoplay.stop();
          isPlaying = false;
        } else {
          this.sliderInstance.autoplay.start();
          isPlaying = true;
        }
      });

    });
  }

} export default Slider;
