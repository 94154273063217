class Grid {
  constructor() {
    this.quads = document.querySelectorAll('.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) + .lc-teaser.lc-grid__height--50.lc-grid__width--25 + .lc-teaser.lc-grid__height--50.lc-grid__width--25 + .lc-teaser.lc-grid__height--50.lc-grid__width--25 + .lc-teaser.lc-grid__height--50.lc-grid__width--25');
    this.doubles = document.querySelectorAll('.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) + .lc-teaser.lc-grid__height--50:not(.lc-grid__width--25) + .lc-teaser.lc-grid__height--50:not(.lc-grid__width--25)');
    this.triples = document.querySelectorAll('.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) + .lc-teaser.lc-grid__height--50:not(.lc-grid__width--25):not(.lc-grid__width--100) + .lc-teaser.lc-grid__height--50.lc-grid__width--25 + .lc-teaser.lc-grid__height--50.lc-grid__width--25');
    this.keyfigures = document.querySelectorAll('.lc-teaser--keyfigure');
    this.projects = document.querySelectorAll('.lc-teaser--project');
    this.contacts = document.querySelectorAll('.lc-teaser--contact');
    this.wrapper = document.querySelectorAll('.lc-teaser--wrapper');
    this.images = document.querySelectorAll('.image-before');
    this.arrowBottoms = document.querySelectorAll('.lc-component--arrow-bottom');
    this.linkBottoms = document.querySelectorAll('.lc-component--link-button');
    this.header = document.querySelector('.iz-header');

    if(this.linkBottoms.length != 0){
      this.bindLinkBottoms();
    }

    if(this.quads.length != 0){
      this.calcQuadGrid();
    }

    if(this.doubles.length != 0){
      this.calcDoubleGrid();
    }

    if(this.triples.length != 0){
      this.calcTriplesGrid();
    }

    if(this.keyfigures.length != 0){
      this.calc100Grid(this.keyfigures, 'lc-teaser--keyfigure');
    }

    if(this.projects.length != 0){
      this.calc100Grid(this.projects, 'lc-teaser--project');
    }

    if(this.wrapper.length != 0){
      this.calc100Grid(this.wrapper, 'lc-teaser--wrapper');
    }

    if(this.images.length != 0){
      this.swapImages(this.images);
    }

    if(this.arrowBottoms.length != 0){
      this.bindArrowBottoms();
    }

    window.addEventListener('resize', () => {
      if(this.quads.length != 0){
        this.calcQuadGrid();
      }

      if(this.doubles.length != 0){
        this.calcDoubleGrid();
      }

      if(this.triples.length != 0){
        this.calcTriplesGrid();
      }

      if(this.keyfigures.length != 0){
        this.calc100Grid(this.keyfigures, 'lc-teaser--keyfigure');
      }

      if(this.projects.length != 0){
        this.calc100Grid(this.projects, 'lc-teaser--project');
      }

      if(this.wrapper.length != 0){
        this.calc100Grid(this.wrapper, 'lc-teaser--wrapper');
      }

      if(this.images.length != 0){
        this.swapImages(this.images);
      }
    });
  }

  calcQuadGrid() {
      this.quads.forEach(tile => {
        if(window.innerWidth > 1024){
          let maxHeight_1, maxHeight_2, maxHeight_3, maxHeight_4, maxHeight_5, maxHeight;

          tile.style.height = "100%";
          tile.previousElementSibling.style.height = "100%";
          tile.previousElementSibling.previousElementSibling.style.height = "100%";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.style.height = "100%";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.style.height = "100%";

          maxHeight_1 = tile.clientHeight;
          maxHeight_2 = tile.previousElementSibling.clientHeight;
          maxHeight_3 = tile.previousElementSibling.previousElementSibling.clientHeight;
          maxHeight_4 = tile.previousElementSibling.previousElementSibling.previousElementSibling.clientHeight;
          maxHeight_5 = tile.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.clientHeight;
          maxHeight = Math.max(maxHeight_1, maxHeight_2, maxHeight_3, maxHeight_4);

          if(maxHeight * 2 < maxHeight_5) {
            maxHeight = maxHeight_5 / 2;
          }

          tile.style.height = maxHeight + "px";
          tile.style.marginTop = -1 * maxHeight + "px";
          tile.previousElementSibling.style.height = maxHeight + "px";
          tile.previousElementSibling.style.marginTop = -1 * maxHeight + "px";
          tile.previousElementSibling.previousElementSibling.style.height = maxHeight + "px";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.style.height = maxHeight + "px";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.style.height = maxHeight * 2 + "px";

        } else {

          tile.style.height = "auto";
          tile.style.marginTop = "0";
          tile.previousElementSibling.style.height = "auto";
          tile.previousElementSibling.style.marginTop = "0";
          tile.previousElementSibling.previousElementSibling.style.height = "auto";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.style.height = "auto";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.style.height = "auto";

        }
      });
  }

  calcDoubleGrid() {
    this.doubles.forEach(tile => {
      if(
        (tile.classList.contains('lc-teaser--wrapper') && tile.previousElementSibling.classList.contains('lc-teaser--wrapper')) ||
        (!tile.classList.contains('lc-teaser--wrapper') && !tile.previousElementSibling.classList.contains('lc-teaser--wrapper'))
      )
      {
        if(window.innerWidth > 1024){

          let maxHeight_1, maxHeight_2, maxHeight_3, maxHeight;

          tile.style.height = "100%";
          tile.previousElementSibling.style.height = "100%";
          tile.previousElementSibling.previousElementSibling.style.height = "100%";

          maxHeight_1 = tile.clientHeight;
          maxHeight_2 = tile.previousElementSibling.clientHeight;
          maxHeight_3 = tile.previousElementSibling.previousElementSibling.clientHeight;
          maxHeight = Math.max(maxHeight_1, maxHeight_2);

          if(maxHeight * 2 < maxHeight_3) {
            maxHeight = maxHeight_3 / 2;
          }

          tile.style.height = maxHeight + "px";
          tile.style.marginTop = -1 * maxHeight + "px";
          tile.previousElementSibling.style.height = maxHeight + "px";
          tile.previousElementSibling.previousElementSibling.style.height = maxHeight * 2 + "px";

        } else {

          tile.style.height = "auto";
          tile.style.marginTop = "0";
          tile.previousElementSibling.style.height = "auto";
          tile.previousElementSibling.previousElementSibling.style.height = "auto";

        }
      }
    });
  }

  calcTriplesGrid() {
    this.triples.forEach(tile => {
      if(tile.previousElementSibling) {
        if(window.innerWidth > 1024){
          let maxHeight_1, maxHeight_2, maxHeight_3, maxHeight_4, maxHeight;

          tile.style.height = "100%";
          tile.previousElementSibling.style.height = "100%";
          tile.previousElementSibling.previousElementSibling.style.height = "100%";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.style.height = "100%";

          maxHeight_1 = tile.clientHeight;
          maxHeight_2 = tile.previousElementSibling.clientHeight;
          maxHeight_3 = tile.previousElementSibling.previousElementSibling.clientHeight;
          maxHeight_4 = tile.previousElementSibling.previousElementSibling.previousElementSibling.clientHeight;
          maxHeight = Math.max(maxHeight_1, maxHeight_2, maxHeight_3);

          if(maxHeight * 2 < maxHeight_4) {
            maxHeight = maxHeight_4 / 2;
          }

          tile.style.height = maxHeight + "px";
          tile.style.marginTop = -1 * maxHeight + "px";
          tile.previousElementSibling.style.height = maxHeight + "px";
          tile.previousElementSibling.style.marginTop = -1 * maxHeight + "px";
          tile.previousElementSibling.previousElementSibling.style.height = maxHeight + "px";
          tile.previousElementSibling.previousElementSibling.style.width = "50%";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.style.height = maxHeight * 2 + "px";

        } else {

          tile.style.height = "auto";
          tile.style.marginTop = "0";
          tile.previousElementSibling.style.height = "auto";
          tile.previousElementSibling.style.marginTop = "0";
          tile.previousElementSibling.previousElementSibling.style.height = "auto";
          tile.previousElementSibling.previousElementSibling.style.width = "100%";
          tile.previousElementSibling.previousElementSibling.previousElementSibling.style.height = "auto";

        }
      }
    });
}

  calc100Grid(tiles, tileClass) {

    tiles.forEach(tile => {
      if(tile.previousElementSibling) {
        if(window.innerWidth <= 1024){

          if(!tile.previousElementSibling.classList.contains(tileClass)){
            tile.previousElementSibling.style.width = "100%";
          }

        } else {

          if(!tile.previousElementSibling.classList.contains(tileClass)){
            tile.previousElementSibling.style.width = "50%";
          }

        }
      }
    });
  }

  swapImages() {
    this.images.forEach(image => {
      if(window.innerWidth <= 1024){

        if(!image.classList.contains('image-moved')){
          image.classList.add('image-moved');
          image.previousElementSibling.insertAdjacentElement('beforebegin', image);
        }

      } else {

        if(image.classList.contains('image-moved')){
          image.classList.remove('image-moved');
          image.nextElementSibling.insertAdjacentElement('afterend', image);
        }

      }
    });
  }

  bindArrowBottoms() {
    this.arrowBottoms.forEach(button => {
      let id = this.makeid();

      let tile = button.closest('.lc-teaser');
      tile.setAttribute('data-id', id);

      let tileClass = tile.nextElementSibling.classList.contains('lc-teaser--wrapper') ? 'lc-teaser--wrapper' : 'lc-teaser--project';
      let nextTile = tile.nextElementSibling;
      let countTiles = 0;

      while (nextTile) {

        if (nextTile.classList.contains(tileClass)) {
          countTiles++;

          if (countTiles >= 4) {
            nextTile.classList.add('lc-teaser--animation');
            nextTile.classList.add('js-arrow-'+id);
          }
        }

        nextTile = nextTile.nextElementSibling;
      }

      button.addEventListener('click', () => {
        if(button.classList.contains('lc-teaser--animated')){
          button.classList.remove('lc-teaser--animated');
          document.querySelectorAll('.js-arrow-'+id).forEach(el => el.classList.add('lc-teaser--animation'));
        } else {
          button.classList.add('lc-teaser--animated');
          document.querySelectorAll('.js-arrow-'+id).forEach(el => el.classList.remove('lc-teaser--animation'));
          const distanceToNextDiv = document.querySelector('.js-arrow-'+id).offsetTop - window.scrollY - this.header.clientHeight;
          window.scrollBy({
            top: distanceToNextDiv,
            behavior: "smooth"
          });
        }
      });

    });
  }

  bindLinkBottoms() {
    this.linkBottoms.forEach(button => {
      button.closest('.lc-teaser').classList.add('lc-teaser--link-bottom');
    });
  }

  makeid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < 5) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return result;
}

} export default Grid;
