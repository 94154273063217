/**
 *                                          __  __
 *    ____  ______      ______ _      __   / /_/ /_  ___  ____ ___  ___
 *   / __ \/ ___/ | /| / / __ \ | /| / /  / __/ __ \/ _ \/ __ `__ \/ _ \
 *  / / / (__  )| |/ |/ / /_/ / |/ |/ /  / /_/ / / /  __/ / / / / /  __/
 * /_/ /_/____/ |__/|__/\____/|__/|__/   \__/_/ /_/\___/_/ /_/ /_/\___/
 *
 * ns.wow WordPress theme
 * by Neidhart + Schön Group
 */


import Anchor from "./Classes/Anchor";
import Grid from "./Classes/Grid";
import FilterPartner from "./Classes/FilterPartner";
import HeroSlider from "./Classes/HeroSlider";
import Hero from "./Classes/Hero";
import Header from "./Classes/Header";
import Infografik from "./Classes/Infografik";
import Questions from "./Classes/Questions";
import Slider from "./Classes/Slider";
import TeaserScroll from "./Classes/TeaserScroll";
import Video from "./Classes/Video";


window.addEventListener("page_event_load", () => {

  new Anchor();
  new Grid();
  new FilterPartner();
  new HeroSlider();
  new Hero();
  new Header();
  new Infografik();
  new Questions();
	new Slider();
	new TeaserScroll();
	new Video();

});
