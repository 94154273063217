class Header {
  constructor() {
    this.izheader = document.querySelector('.iz-header');
    this.izcontent = document.querySelector('.iz-content__content');

    if(this.izcontent) {
      this.handleHeader();
      this.handleScrollbar();
      this.handleScroll();
    }

    window.addEventListener('resize', () => this.handleScrollbar());
    window.addEventListener('scroll', () => this.handleScrollTransition());
  }

  handleHeader() {
    this.izheader.classList.remove('iz-header--transition');

    if(this.izcontent.firstChild.classList.contains('lc-hero') || this.izcontent.firstChild.classList.contains('lc-hero-slider')){
      this.izheader.classList.remove('iz-header--black');
    } else {
      this.izheader.classList.add('iz-header--black');
    }
  }

  handleScrollbar() {
    if(window.innerWidth == document.body.clientWidth){
      this.izheader.classList.remove('iz-header--scrollbar');
    } else {
      this.izheader.classList.add('iz-header--scrollbar');
    }
  }

  handleScrollTransition() {
    if(!this.izheader.classList.contains('iz-header--transition')){
      this.izheader.classList.add('iz-header--transition');

      setTimeout(() => {
        this.izheader.classList.remove('iz-header--transition');
      },400);
    }

    this.handleScroll()
  }


  handleScroll() {
    if(window.scrollY !== 0){
      this.izheader.classList.add('iz-header--scrolled');
    } else {
      this.izheader.classList.remove('iz-header--scrolled');
    }
  }

} export default Header;
