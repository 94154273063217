class FilterPartner {
  constructor() {
    this.buttonAll = document.querySelector('.lc-button.partner-all');
    this.buttonPublish = document.querySelector('.lc-button.partner-nspublish');
    this.buttonWow = document.querySelector('.lc-button.partner-nswow');
    this.buttonOthers = document.querySelector('.lc-button.partner-others');

    this.teaser = document.querySelectorAll('.lc-teaser--wrapper');
    this.teaserPublish = document.querySelectorAll('.lc-teaser.partner-nspublish');
    this.teaserWow = document.querySelectorAll('.lc-teaser.partner-nswow');
    this.teaserOthers = document.querySelectorAll('.lc-teaser.partner-others');
    this.teaserCats = document.querySelectorAll('.partner-category');

    this.header = document.querySelector('.iz-header');
    this.hash = window.location.hash;

    if(this.buttonAll && this.buttonPublish && this.buttonWow && this.buttonOthers){
      this.bindButtons();
    }

    if(this.teaserCats.length != 0){
      this.addTeaserStart();
    }
  }

  bindButtons() {
    this.buttonPublish.addEventListener('click', () => {
      this.resetTeaserCorrection();
      this.changeTeaserCategories();
      this.updateButtons(this.buttonPublish);
      this.filterTeaser(this.teaserPublish);
      this.updateHash('nspublish');
    });

    this.buttonWow.addEventListener('click', () => {
      this.resetTeaserCorrection();
      this.changeTeaserCategories();
      this.updateButtons(this.buttonWow);
      this.filterTeaser(this.teaserWow);
      this.updateHash('nswow');
    });

    this.buttonOthers.addEventListener('click', () => {
      this.resetTeaserCorrection();
      this.changeTeaserCategories();
      this.updateButtons(this.buttonOthers);
      this.filterTeaser(this.teaserOthers);
      this.updateHash('esg');
    });

    this.buttonAll.addEventListener('click', () => {
      this.resetTeaserCorrection();
      this.updateButtons();
      this.resetTeaserCategories();
      this.updateHash('');
    });

    if(this.hash) {
      setTimeout(() => {
        if(this.hash == '#nspublish') {
          this.buttonPublish.dispatchEvent(new Event('click'));
        } else if(this.hash == '#nswow') {
          this.buttonWow.dispatchEvent(new Event('click'));
        } else if(this.hash == '#esg') {
          this.buttonOthers.dispatchEvent(new Event('click'));
        }
      }, 100);
    } else {
      this.updateButtons(this.buttonAll, false);
    }
  }

  updateButtons(button = this.buttonAll, animate = true) {
    document.querySelectorAll('.lc-button--filteractive').forEach(active => { active.classList.remove('lc-button--filteractive'); });
    button.classList.add('lc-button--filteractive');

    if(animate) {
      let distanceToNextDiv = this.teaserCats[0].offsetTop - window.scrollY - this.header.clientHeight;
      window.scrollBy({
        top: distanceToNextDiv,
        behavior: "smooth"
      });
    }
  }

  updateHash(hash) {
    if(hash != '') {
      history.pushState(null, null, '#' + hash);
    } else {
      history.pushState(null, null, ' ');
    }
  }

  filterTeaser(teaseElements) {
    this.teaser.forEach(teaser => {
      teaser.classList.add('partner-hide');
    });

    teaseElements.forEach(teaser => {
      teaser.classList.remove('partner-hide');
    });

    this.updateTeaser();
  }

  resetTeaserCorrection() {
    document.querySelectorAll('.partner-hide').forEach(teaser => { teaser.classList.remove('partner-hide'); });
    document.querySelectorAll('.reverted').forEach(teaser => { teaser.classList.remove('reverted'); });
    document.querySelectorAll('.inverted').forEach(teaser => { teaser.classList.remove('inverted'); });
    document.querySelectorAll('.reverted--ta').forEach(teaser => { teaser.classList.remove('reverted--ta'); });
    document.querySelectorAll('.inverted--ta').forEach(teaser => { teaser.classList.remove('inverted--ta'); });

    window.dispatchEvent(new Event('resize'));
  }

  resetTeaserCategories() {
    document.querySelectorAll('.lc-grid__height--corrected').forEach(teaser => {
      teaser.classList.remove('lc-grid__height--50');
      teaser.classList.remove('lc-grid__height--corrected');
    });
  }

  changeTeaserCategories() {
    this.teaserCats.forEach(teaser => {
      if(!teaser.classList.contains('lc-grid__height--50')){
        teaser.style.cssText = '';
        teaser.classList.add('lc-grid__height--50');
        teaser.classList.add('lc-grid__height--corrected');
      }
    });
  }

  updateTeaser() {
    let classReverted = ['reverted','inverted','inverted','reverted','reverted','inverted','inverted','reverted','reverted','inverted','inverted'];
    let classInverted = ['inverted','reverted','reverted','inverted','inverted','reverted','reverted','inverted','inverted','reverted','reverted'];
    let lastClassReverted = 'reverted';

    this.teaserCats.forEach(cat => {

      let classReIn = classReverted;

      if(lastClassReverted == 'inverted'){
        classReIn = classInverted;
      }

      let nextElement = cat.nextElementSibling;
      let counter = 0;

      while (nextElement !== null && !nextElement.classList.contains('partner-category')) {

        if (nextElement.classList.contains('lc-teaser--wrapper') && !nextElement.classList.contains('partner-hide')) {
          nextElement.classList.add(classReIn[counter]);
          if(counter % 2 == 0) {
            nextElement.classList.add('reverted--ta');
          } else {
            nextElement.classList.add('inverted--ta');
          }
          counter++;
        }

        nextElement = nextElement.nextElementSibling;
      }

      if(counter == 0) {
        cat.classList.add('partner-hide');
      }

      lastClassReverted = classReIn[counter];
    });
  }

  addTeaserStart(){
    this.teaserCats.forEach(cat => {
      let teaserStart = '<div class="lc-teaser-start"></div>';
      cat.insertAdjacentHTML("beforebegin", teaserStart);
    });

    document.querySelector('.lc-hero__buttons').classList.add('lc-hero__buttons--filter');
    this.buttonPublish.insertAdjacentHTML("afterend", '<div class="lc-button--filler"></div>');
  }

} export default FilterPartner;
