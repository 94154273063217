  class Anchor {
  constructor() {
    this.header = document.querySelector('.iz-header');
    this.hashlinks = Array.prototype.slice.call(document.querySelectorAll('a[href*="#"'));

    this.scrollToHash();

    if(this.hashlinks.length != 0){
      this.addScrollTo();
    }
  }

  addScrollTo() {
    this.hashlinks.forEach(link => {
      link.addEventListener('click', () => {
        this.scrollToHash();
      });
    });
  }

  scrollToHash() {
    const hash = window.location.hash;
    if (hash) {
      this.scrollToElementWithOffset(hash);
    }
  }

  scrollToElementWithOffset(hash) {
    if(document.querySelector(hash)) {
      const distanceToNextDiv = document.querySelector(hash).offsetTop - window.scrollY - this.header.clientHeight;
      window.scrollBy({
        top: distanceToNextDiv,
        behavior: "smooth"
      });
    }
  }

}

export default Anchor;

