class Hero {
  constructor() {
    this.hero = document.querySelector('.lc-hero-slider');
    this.scroll = document.querySelector('.lc-hero__scroll');
    this.video = document.querySelector('.lc-hero__video > *');
    this.header = document.querySelector('.iz-header');

    if(this.scroll) {
      this.bindScroll();
    }

    if(this.video) {
      this.bindVideo();
    }
  }

  bindScroll() {
    if(!this.hero){
      this.hero = document.querySelector('.lc-hero');
    }

    this.scroll.addEventListener('click', () => {
      const distanceToNextDiv = this.hero.nextElementSibling.offsetTop - window.scrollY - this.header.clientHeight;
      window.scrollBy({
        top: distanceToNextDiv,
        behavior: "smooth"
      });
    });
  }

  bindVideo() {
    this.hero.style.backgroundImage = "";
    if(this.video.tagName.toLowerCase() === "iframe"){
      this.video.src = this.video.src + '&autoplay=1';
    }
  }

} export default Hero;
