class TeaserScroll {
  constructor() {
    this.scroll = document.querySelector('.lc-teaser__scroll');
    this.header = document.querySelector('.iz-header');

    if(this.scroll) {
      this.bindScroll();
    }
  }

  bindScroll() {
    this.scroll.addEventListener('click', () => {
      const distanceToNextDiv = document.querySelector('.lc-questions-container').offsetTop - window.scrollY - this.header.clientHeight;
      window.scrollBy({
        top: distanceToNextDiv,
        behavior: "smooth"
      });
    });
  }

} export default TeaserScroll;
