import Swiper from '../lib/swiper';

class HeroSlider {
	constructor() {
    this.slider = document.querySelector('.lc-hero-slider');
    this.sliderItems = document.querySelectorAll('.lc-hero-slider .lc-hero');
    this.playpause = document.querySelector('.lc-hero-slider__playpause');

    if(this.slider){
      this.initSlider();
      this.initPlayPause();
    }
  }

  initSlider() {
    this.sliderInstance = new Swiper('.lc-hero-slider', {
      autoplay: {
        delay: 3500
      },
      loop: true,
      speed: 500,
      slideClass: 'lc-hero-slider-item',
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      on: {
        slideChange: function() {
          const index_currentSlide = this.activeIndex;
          const currentSlide = this.slides[index_currentSlide];
          const header = document.querySelector('.iz-header');

          if(
            currentSlide.classList.contains('lc-component--background-blue') ||
            currentSlide.classList.contains('lc-component--background-orange') ||
            currentSlide.classList.contains('lc-component--background-yellow') ||
            currentSlide.classList.contains('lc-component--background-darkgrey')
          ) {
            header.classList.add('iz-header--menu-white');
          } else {
            header.classList.remove('iz-header--menu-white');
          }

          if(index_currentSlide == 5) {
            document.querySelector('.lc-hero-slider__playpause').dispatchEvent(new Event('click'));
          }

        },
      }
    });
  }

  initPlayPause() {
    let isPlaying = true;

    this.playpause.addEventListener('click', () => {
      if(isPlaying) {
        this.sliderInstance.autoplay.stop();
        this.playpause.classList.add('paused');
        isPlaying = false;
      } else {
        this.sliderInstance.autoplay.start();
        this.playpause.classList.remove('paused');
        isPlaying = true;
      }
    });
  }


} export default HeroSlider;
