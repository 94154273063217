class Questions {
  constructor() {
    this.questionsContainer = document.querySelector('.lc-questions-container');
    this.questionsItems = document.querySelectorAll('.lc-questions-item');
    this.questionsButtonsA = document.querySelectorAll('.lc-button--a');
    this.questionsButtonsB = document.querySelectorAll('.lc-button--b');

    this.nswowLink = document.querySelector('.iz-navigation__menu > li:nth-child(2) > a').getAttribute('href');
    this.nspublishLink = document.querySelector('.iz-navigation__menu > li:first-child > a').getAttribute('href');

    this.header = document.querySelector('.iz-header');

    this.points = 0;
    this.slideCount = 0;

    if(this.questionsContainer) {
      this.initHeight();
      this.initButtons();
    }

    window.addEventListener('resize', () => {
      if(this.questionsContainer) {
        this.initHeight();
      }
    });

  }

  initHeight() {
    let maxHeight = 0;
    let itemsCounter = 0;

    this.questionsItems.forEach((item, index) => {
      if(item.clientHeight > maxHeight) {
        maxHeight = item.clientHeight;
      }

      item.classList.add('positioned');
      item.classList.add('lc-questions-item-' + itemsCounter);
      itemsCounter += 1;
    });

    this.questionsContainer.style.height = maxHeight + "px";

      this.questionsItems.forEach(item => {
        if(window.innerWidth > 1024){
          item.querySelector('.lc-teaser:not(.lc-grid__height--50)').style.height = maxHeight + "px";
          item.querySelector('.lc-teaser.lc-component--background-darkgrey').style.height = maxHeight/2 + "px";
          item.querySelector('.lc-teaser.lc-component--background-sand').style.height = maxHeight/2 + "px";
          item.querySelector('.lc-teaser.lc-component--background-sand').style.marginTop = -1 * maxHeight/2 + "px";
        } else if(window.innerWidth > 768) {
          item.querySelector('.lc-teaser:not(.lc-grid__height--50)').style.height = maxHeight/2 + "px";
          item.querySelector('.lc-teaser.lc-component--background-darkgrey').style.height = maxHeight/2 + "px";
          item.querySelector('.lc-teaser.lc-component--background-sand').style.height = maxHeight/2 + "px";
          item.querySelector('.lc-teaser.lc-component--background-sand').style.marginTop = "0";
        } else {
          item.querySelector('.lc-teaser:not(.lc-grid__height--50)').style.height = "auto";
          item.querySelector('.lc-teaser.lc-component--background-darkgrey').style.height = "auto";
          item.querySelector('.lc-teaser.lc-component--background-sand').style.height = "auto";
          item.querySelector('.lc-teaser.lc-component--background-sand').style.marginTop = "0";
        }
      });
  }

  initButtons() {
    this.questionsItems[0].classList.add('lc-questions-item-active');

    this.questionsButtonsA.forEach(button => {
      button.addEventListener('click', (e) => {
        this.updatePoint(2);
      });
    });

    this.questionsButtonsB.forEach(button => {
      button.addEventListener('click', (e) => {
        this.updatePoint(1);
      });
    });

  }

  updatePoint(points) {
    this.points += points;
    this.slideCount += 1;

    const distanceToNextDiv = this.questionsContainer.offsetTop - window.scrollY - this.header.clientHeight;
      window.scrollBy({
        top: distanceToNextDiv,
        behavior: "smooth"
    });

    if(this.slideCount <= 4) {
      document.querySelector('.lc-questions-item-active').classList.remove('lc-questions-item-active');
      document.querySelector('.lc-questions-item-' + this.slideCount).classList.add('lc-questions-item-active');
    }

    if(this.slideCount == 4){
      this.resultItem = document.querySelector('.lc-questions-item-4');

      if(this.points <= 6) {
        this.resultItem.querySelector('.lc-component--background-darkgrey').style.opacity = .1;
        this.resultItem.querySelector('.lc-component--background-darkgrey').style.pointerEvent = 'none';
        this.resultItem.querySelector('.lc-component--background-sand .lc-button').addEventListener('click', () => {
          window.location.href = this.nswowLink;
        });
      } else {
        this.resultItem.querySelector('.lc-component--background-sand').style.opacity = .1;
        this.resultItem.querySelector('.lc-component--background-sand').style.pointerEvent = 'none';
        this.resultItem.querySelector('.lc-component--background-darkgrey .lc-button').addEventListener('click', () => {
          window.location.href = this.nspublishLink;
        });
      }
    }
  }

} export default Questions;
