class Infografik {
  constructor() {
    this.infografiks = Array.prototype.slice.call(document.querySelectorAll('.svg-infografik'));
    this.buttons = Array.prototype.slice.call(document.querySelectorAll('.svg-infografik-button'));
    this.popups = Array.prototype.slice.call(document.querySelectorAll('.svg-infografik-popup'));
    this.hrefs = Array.prototype.slice.call(document.querySelectorAll('.svg-infografik-href'));
    this.close = Array.prototype.slice.call(document.querySelectorAll('.svg-infografik--close'));

    this.nspublishDe = "https://mmssolutions.io/de/nspublish#kerngrafik_nspublish"
    this.nswowDe = "https://mmssolutions.io/de/nswow#Kerngrafik_nswow"
    this.nspublishEn = "https://mmssolutions.io/en/nspublish#kerngrafik_nspublish"
    this.nswowEn = "https://mmssolutions.io/en/nswow#Kerngrafik_nswow"

    this.hash = window.location.hash;
    this.header = document.querySelector('.iz-header');

    if(this.buttons) {
      this.bindButtons();
    }

    if(this.hash) {
      setTimeout(() => {
        let hash = this.hash.split('#')[1];
        try {
          console.log("d");
          console.log("di", document.querySelector("g[data-button*=" + hash + "]").closest('.lc-teaser'));
          document.querySelector("g[data-button*=" + hash + "]").dispatchEvent(new Event('click'));
          const distanceToNextDiv = document.querySelector("g[data-button*=" + hash + "]").closest('.lc-teaser').offsetTop - window.scrollY - this.header.clientHeight;
          window.scrollBy({
            top: distanceToNextDiv,
            behavior: "smooth"
          });
        } catch (error) {
          console.log("error", error);
        }
      }, 100);
    }
  }

  bindButtons() {
    this.buttons.forEach(button => {
      button.addEventListener('click', () => {
        document.querySelector("." + button.getAttribute('data-button')).classList.toggle('svg-infografik-popup--active');

        this.infografiks.forEach(infografik => {
          infografik.classList.toggle('svg-infografik--disabled');
        });
      });
    });

    this.close.forEach(button => {
      button.addEventListener('click', () => {
        this.closePopup();
      });
    });

    this.hrefs.forEach(href => {
      href.addEventListener('click', () => {
        if(href.classList.contains('svg-infografik-href--nswow-de')){
          window.location.href = this.nswowDe;
        } else if(href.classList.contains('svg-infografik-href--nswow-en')){
          window.location.href = this.nswowEn;
        } else if(href.classList.contains('svg-infografik-href--nspublish-de')){
          window.location.href = this.nspublishDe;
        } else if(href.classList.contains('svg-infografik-href--nspublish-en')){
          window.location.href = this.nspublishEn;
        }
      });
    });
  }

  closePopup(){
    this.popups.forEach(popup => {
      popup.classList.remove('svg-infografik-popup--active');
    });

    this.infografiks.forEach(infografik => {
      infografik.classList.remove('svg-infografik--disabled');
    });
  }

} export default Infografik;
